<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                Ajouter une commande
                <router-link to="/commandes">
                  <span class="float-right btn btn-info"> retour </span>
                </router-link>
              </div>
              <div class="container">
                <form @submit.prevent="addcommande()">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Numéro commande
                          <span class="obligatoire">*</span></label
                        >
                        <input
                          value=""
                          class="form-control"
                          placeholder="Recherche par code"
                          type="text"
                          required
                          v-model="commande.numero"
                        />
                      <span class="text-danger" id="commande_validation" style="display:none">La commande existe déja</span>

                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Date commande
                          <span class="obligatoire">*</span></label
                        >
                        <input
                          value=""
                          class="form-control"
                          placeholder="Recherche par code"
                          type="date"
                          required

                          v-model="commande.date_commande"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Choisir le commercial
                          <span class="obligatoire">*</span></label
                        >

                        <v-select
                          :multiple="false"
                          :closeOnClick="false"
                          v-model="commande.user_id"
                          :options="users"
                          required
                          :reduce="(users) => users._id"
                          label="name"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Choisir le client
                          <span class="obligatoire">*</span></label
                        >

                        <v-select
                          :multiple="false"
                          :closeOnClick="false"
                          v-model="commande.client_id"
                          :options="clients"
                          required
                          :reduce="(clients) => clients._id"
                          label="name"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Choisir les produits
                          <span class="obligatoire">*</span></label
                        >

                        <v-select
                          :multiple="true"
                          required
                          :closeOnClick="false"
                          v-model="commande.produits"
                          :options="produits"
                          :reduce="(produits) => produits"
                          label="reference"
                        />
                      </div>
                    </div>
                    <div class="col-md-12" v-if="commande.produits.length != 0">
                      <div class="form-group">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Réference du produit</th>
                              <th>Quantités</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(produit, index) in commande.produits"
                              :key="index"
                            >
                              <td>
                                {{ produit.reference }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="form-control"
                                  :min="1"
                                  v-model="produit.quantites"
                                  required
                                  
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <br />
                        <button type="submit" class="btn btn-primary"
                        :disabled="commande.produits.length == 0 || commande.client_id.length==0  || 
                        commande.user_id.length==0 ||!commande.date_commande ||!commande.numero "
                        >
                          + Ajouter la commande
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";

import moment from "moment";
export default {
  data() {
    return {
      commande: {
        numero: "",
        client_id: "",
        user_id: "",
        produits: [],
        date_commande: "",
      },
      users: [],
      clients: [],
      error: "",
    };
  },

  created() {
    this.getproduits();
    this.getclients();
    this.getusers();
  },
  methods: {
    getusers() {
      HTTP.get("users/getuserscommercial")
        .then((response) => {
          
          this.users = response.data;
        })
        .then((err) => console.log(err));
    },

    getclients() {
      HTTP.get("clients/getclients")
        .then((response) => {
          this.clients = response.data;
        })
        .then((err) => console.log(err));
    },

    getproduits() {
      HTTP.get("produits/getproduitfields")
        .then((response) => {
          this.produits = response.data;
          // this.produits.forEach((produit) => {
          //   produit.quantites = "";
          //   produit.produit_id = produit._id;
          // });
        })
        .then((err) => console.log(err));
    },

    addcommande() {

      HTTP.post("commandes/addcommande", this.commande).then(
        (response) => {
          console.log(response);
          Toast.fire({
            icon: "success",
            title: "Commande ajouté",
          });

          this.$router.push("/commandes");
        },
        (err) => {
          this.error = err.response.data;
          if(this.error.title=='exist'){


   $('#commande_validation').show()

setTimeout(function() {
     $('#commande_validation').fadeOut('fast');
}, 5000); 


          }
        }
      );
    },
  },
};
</script>
